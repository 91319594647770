// we should use this component for uniform rendering of RT and historical metrics
import SingleMetric from "ui-component/stats/SingleMetric";

import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import { Tooltip, Typography } from '@mui/material';

import { useEffect, useState, useMemo } from 'react';
// import { useChartData } from "./hooks/statsHook"; // dont use this here to prevent endless server calls

import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const SKIP_TYPES = ['selfcheck', 'eas'];

// ==============================|| TABLE -  Metric live icon ||============================== //

const RealTimeMetric = ({ type, dev_or_loc, metric, icon, hide_updated, as, offset, icon_pos, border, auto_refresh }) => {


    // const dev_list = dev_or_loc.devs ? dev_or_loc.devs.filter(d=> d.device_type !== 'selfcheck') : [dev_or_loc];
    const now = new Date();    
    const base_count = !!offset ? offset : 0; 
    const rt_visits = useSelector(state => state.admin.rt_visits);
    // const semi_rt_visits = useSelector(state => state.admin.semi_rt_visits);
    
    const devices = useSelector((state) => state.admin?.device);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(false); // ALL dev in the list have a loading error
    const [is_refreshing, set_is_refreshing] = useState(false); // ALL dev in the list have a loading error
    const [max_age, set_max_age] = useState( auto_refresh / 2 || 10 * 60 * 1000 );
    
    // extract the list of devices we need to collect and aggrigate data from
    const dev_list = useMemo(() => {
        if(!!dev_or_loc.device_type){
            return [dev_or_loc]; // array of ONE device
        }
        const raw_dev_list = devices?.filter(dev => !dev?.hidden && !SKIP_TYPES.includes(dev?.device_type) && dev_or_loc?.id === dev?.location?.id);
        // if we have a mixed dev_list we can skip the non RT devices
        if( raw_dev_list.length > 0 ){
            const dev_types = {};
            for( const dev of raw_dev_list ){
                dev_types[dev.device_type] = true;
            }
            const seen_types = Object.keys(dev_types);
            if( seen_types.length > 1 ){
                return raw_dev_list.filter(dev => !dev.device_type.startsWith('e')); // ALL non eco and eas types 
            }
        }
        return raw_dev_list;
    }, [dev_or_loc, devices]);
    
    let metricValueOrName = 0;
    let last_seen = null;
    for( const dev of dev_list ){
        const dev_last_seen = rt_visits?.[dev.id]?.modifiedOn || rt_visits?.[dev.id]?.iso_date;
        const dev_last_seen_date = new Date( dev_last_seen ); // we may get dates as strings mixed with date object ..

        // check if last seen data for each device is to old to show        
        if( now.getHours() > 5 ){
            let midnight = new Date();
            midnight.setHours(0,  0,  0,  0);    
            if( dev_last_seen_date < midnight ) continue; // no need to add this one
        }
        // the most recent seen devices is the overall last seen date for a location
        if( !last_seen || last_seen < dev_last_seen_date ){
            last_seen = dev_last_seen_date;
        } 
         
        if( metric.id !== 'balance' ){
            metricValueOrName += rt_visits?.[dev.id]?.[metric.id] || 0;
        } else {
            if( rt_visits?.[dev.id]?.today_enters != undefined && rt_visits?.[dev.id]?.today_exits != undefined){
                metricValueOrName += rt_visits?.[dev.id]?.today_enters - rt_visits?.[dev.id]?.today_exits;
            }
        }
    }
    // correct negative visitor balance for locations
    if( metric.id === 'balance' && !dev_or_loc?.device_type && metricValueOrName < 0 ) metricValueOrName = 0;
    

    // please pass in the complete object in dev_or_loc
    if (!icon_pos) icon_pos = 'right';
    const {get_rt_visits } = useAuth();

    // const database = getDatabase();
    const icon_only = as === 'icon';
    const text_only = as === 'text';
    
    const force_refresh = async () => {
        console.log("start forced refresh RT data");
        set_is_refreshing(true);
        set_max_age(-1); // this will trigger the use effect
    }

    useEffect(() => {
        const fetchLiveCounts = async () => {
          for(const dev of dev_list ){
            if( rt_visits[dev.id] ){
                if(rt_visits[dev.id].loading ) continue; // don't trigger a new api call...
                if(rt_visits[dev.id].error ) continue; // don't trigger a new api call...
                
                if(rt_visits[dev.id].updated){
                    const data_age = new Date() - rt_visits[dev.id].updated;
                    if( data_age < max_age ) continue; // data is up to date enough, so use is without refresh
                }
            }
            if( dev.device_type.startsWith('eco') || dev.device_type.startsWith('eas')){
            // if( dev.device_type.startsWith('eas')){
                 continue;
            }
            get_rt_visits(dev);

          };

          if( auto_refresh > 0 ){
            // TODO: we may want to adapt the frequency depending on the change in the counts
            const min = auto_refresh / 2 ;
            const max = auto_refresh * 2; // adjust this as needed
            const randomInterval = Math.floor(Math.random() * (max - min + 1) + min);
            setTimeout(fetchLiveCounts, randomInterval);
          };
      
        };

        let all_loaded = true;
        let all_error = true;
        for(const dev of dev_list ){
            
            if( dev.device_type.startsWith('eco') && !rt_visits?.[dev.id] ){
                // eco and eas dont have RT data
                all_error = false;
                continue;
            }
            if( dev.device_type.startsWith('eas') && !rt_visits?.[dev.id] ){
                // eco and eas dont have RT data
                all_error = false;
                continue;
            } 
            if( rt_visits?.[dev.id]  === undefined ){
                // looks like this is our first hit on this device
                all_loaded = false;
                all_error= false;
                // get_rt_visits(dev); // TODO: we may move the data retr calls out of the fetch call 
                continue
            }
            if( rt_visits?.[dev.id]?.error ){
                //
            } else {
                all_error = false; 
                if( !rt_visits?.[dev.id]?.updated ){
                    if( rt_visits?.[dev.id]?.loading ){
                        all_loaded = false;
                    } else {
                        console.warn(` -- missing RT data loading state for ${dev.id} for loc ${dev_or_loc}`)
                    }
                }
            }
        }
        setLoading(!all_loaded);
        setError(all_error);
        if( !all_loaded || max_age < 0) fetchLiveCounts()
            
        if (auto_refresh > 0) {
            // start triggering the RT updates now
            setTimeout(fetchLiveCounts, 250);
        }
        if( max_age < 0 ){
            set_max_age(auto_refresh / 2 || 10 * 60 * 1000 )
            set_is_refreshing( false );
        }
    }, [dev_list, get_rt_visits, rt_visits, auto_refresh, max_age]);
        
    return (

        <>
            {/* <pre>--{JSON.stringify(rt_visits?.[dev_or_loc.id], null, 2)}--</pre> */}
            {/* <pre>{JSON.stringify(metric, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            
            {icon_only && <Tooltip
                placement="top"
                title={<>
                    <FormattedMessage id={metric.id} />
                    {!!rt_visits && rt_visits.iso_date &&  <>
                        <span> </span>
                        <FormattedDate value={last_seen} year="numeric" month="long" day="2-digit" />
                        <span> - </span>
                        <FormattedTime value={last_seen} />
                    </>
                    }
                </>}
            >
                <Typography color={metric.colorscheme}>
                    {icon_pos === 'left' && <Typography component="span" fontSize='11px' style={{ opacity: !rt_visits ? '0.4' : '1', position: 'relative', top: '6px', marginLeft: '6px' }}>{icon}</Typography>}
                    
                    {loading && <CircularProgress color="primary" size={15}/>}
                    {!loading &&  error && <span>?</span>}
                    {!loading && !error && <FormattedNumber value={metricValueOrName + base_count}></FormattedNumber>}
                    
                    {icon_pos !== 'left' && <Typography component="span" fontSize='11px' style={{ opacity: !rt_visits ? '0.4' : '1', position: 'relative', top: '6px', marginLeft: '6px' }}>{icon}</Typography>}
                </Typography>
            </Tooltip>}
            {/* {
                text_only && <Box mt={1}><Typography variant="bold"><FormattedMessage id="balance" />: { !liveCounts ? '?' : <FormattedNumber value={liveCounts[metric.id] + base_count}></FormattedNumber> }</Typography></Box>
            } */}
            {!icon_only && !text_only && <SingleMetric
                refresh={force_refresh}
                isLoading={loading || max_age < 0}
                border={border}
                label={metric.id}
                icon={metric.icon}
                last_seen={last_seen}
                amount={metricValueOrName}
                colorscheme={metric.colorscheme} />
            }
        </>
    );
};

export default RealTimeMetric;
