import { FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import RefeshIcon from '@mui/icons-material/RefreshTwoTone';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

import SkeletonLoader from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import LogoutIcon from '@mui/icons-material/LogoutTwoTone';
import LoginIcon from '@mui/icons-material/LoginTwoTone';
import LocalLibraryIcon from '@mui/icons-material/LocalLibraryTwoTone';
// import PaymentIcon from '@mui/icons-material/EuroTwoTone';
import PaymentIcon from '@mui/icons-material/PaymentTwoTone';

import InfoIcon from '@mui/icons-material/InfoTwoTone';
import RenewIcon from '@mui/icons-material/RefreshTwoTone';
import BalanceIcon from '@mui/icons-material/SpeedTwoTone';
import AssignmentIcon from '@mui/icons-material/AssignmentTwoTone';
import FadeInContainer from 'ui-component/FadeInContainer';

const SHOW_LASTUPDATED = false; // TODO: make this part of config

// styles

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    height: '200px',
    minWidth: '100%',
    maxWidth: '200px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    }
}));

// ==============================|| DASHBOARD - generic metric card ||============================== //

const SingleMetric = ({ isLoading, label, amount, last_seen, colorscheme, icon, refresh, disabled = false, border = true }) => {

    const theme = useTheme();
    let useIcon;

    switch (icon) {
        case 'enter':
            useIcon = <LoginIcon fontSize="inherit" />;
            break;
        case 'exit':
            useIcon = <LogoutIcon fontSize="inherit" />;
            break;
        case 'alert':
            useIcon = <NotificationsActiveIcon fontSize="inherit" />;
            break;
        case 'library':
            useIcon = <LocalLibraryIcon fontSize="inherit" />;
            break;
        case 'info':
            useIcon = <InfoIcon fontSize="inherit" />;
            break;
        case 'balance':
            useIcon = <BalanceIcon fontSize="inherit" />;
            break;
        case 'renew':
            useIcon = <RenewIcon fontSize="inherit" />;
            break;
        case 'payment':
            useIcon = <PaymentIcon fontSize="inherit" />;
            break;    
        default:
            useIcon = <AssignmentIcon fontSize="inherit" />;
    };
    
    return (
            <CardWrapper border={border} content={false} colorscheme={colorscheme}>
                <Box sx={{ opacity: disabled ? 0.4 : 1 }}>

                    <Box p={2} sx={{ height: '55px' }}>
                        {label && <Typography><FormattedMessage id={label} /></Typography>}
                    </Box>
                    <FadeInContainer visible={isLoading}>
                        <SkeletonLoader/>
                    </FadeInContainer>
                    <FadeInContainer visible={!isLoading}>

                        <Box sx={{ height: '100px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {refresh && 
                                <Button style={{position: 'absolute', top:10, right: 10}} disabled={isLoading}
                                    onClick={refresh} 
                                    variant="icon" color="secondary" endIcon={<RefeshIcon />}>
                                    {/* {!isLoading ? <FormattedMessage id='refresh'/> : <FormattedMessage id='please_wait'/>} */}
                                </Button>
                            }
                            <span style={{ fontSize: '28px', marginLeft: '-15px', marginRight: '5px', color: colorscheme }}>{useIcon}</span><span>
                                {(amount === undefined || isNaN(Number(amount))) ? 
                                    <Typography variant="h4" sx={{ marginLeft: '5px' }}>
                                        <FormattedMessage id="not-available" />
                                    </Typography> : <Typography variant="h1" sx={{ marginLeft: '5px' }}>
                                        <FormattedNumber value={amount} />
                                    </Typography> 
                                }            
                            </span>
                        </Box>
                        {SHOW_LASTUPDATED && last_seen &&  
                            <Typography
                                variant="subtitle"
                                sx={{
                                    color: theme.palette.grey[500],
                                    mt: 0.5,
                                    marginLeft: '15px',
                                    display: 'block'
                                }}>
                                <FormattedMessage id="last-updated" />
                                <span> <br/> </span>
                                <FormattedDate value={last_seen} year="numeric" month="long" day="2-digit" />
                                <span> - </span>
                                <FormattedTime value={last_seen} />
                            </Typography>}
                    </FadeInContainer>
                </Box>
            </CardWrapper>
           
    );
};

export default SingleMetric;
