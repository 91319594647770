// action - state management
import { SET_ITEMS_FOR_RESOURCE, SEARCH_AND_ORDER, SET_SELECTED_ITEM, SET_ACTIVE_ITEM, ADD_ITEM_TO_RESOURCE, RESET_ITEMS_FOR_RESOURCE, LOADING_DATA, SET_RT_VISITS_DATA, UPDATE_ITEM_FOR_RESOURCE } from './actions';

// import admin from 'menu-items/admin';
import { get_rt_counts } from "../utils/extract_counts";

const RESOURCE_HIERARCHY = {
    root: ['organisation'],
    organisation: ['location', 'device', 'user', 'reporting', 'token', 'promo', 'config'],
    location: ['device', 'reporting', 'promo', 'config'],
    reporting: [],
    device: [],
    user: ['token'],
    token: [],
    promo: [],
    config: []
};
const POPULATE_RESOURES = ['organisation', 'location', 'device', 'user'];

let start = {
    rt_visits: {},    // real time data indexed by device_id
    semi_rt_visits: {},    // stats data to be used as almost real time data indexed by device_id
    stats: {},       // stats indexed by path
    searchValue: '', // TODO: is this still used ?
    loadingData: true,
};
for (const resource in RESOURCE_HIERARCHY) {
    if (resource === 'root') continue;
    start[resource] = null // null means server is not hit yet!  [] means no data found on server 
    start[`${resource}Filtered`] = null; // filtered by context
    start[`${resource}Displayed`] = null; // Filterd + ordered and searched
    start[`${resource}Selected`] = null; // ONE object we want to filter dependend objects on
    start[`${resource}Active`] = null; // ONE object we want to edit
    start[`${resource}Search`] = ''; // string of search keywords
    start[`${resource}Order`] = { orderBy: 'created', order: 'asc' };
};

export const initialResourceState = start;

const enhance_item = (r, item, state) => {
    // add a list of device devices for the real time data
    if (r === 'device') {
        // item.devs = [item.id];  // TODO: WHAT is this? A device is always just ONE !?
        // TODO: this code should not be needed ....
        if (!item.rt_id) {
            const guess_rt = item.id.split('-', 1).length > 1 ? item.id.split('-', 1)[1] : item.id
            item.rt_id = !!item.login ? item.login : guess_rt;
        }
        item.stats_link = `/dashboard/stats/${r}/${item.id}/${item.rt_id}`;
        // TODO: refactor links to a stats<LOC>/<DEV> type structure ?
        item.stats_link_new = `/dashboard/stats/${item.location.id}|${item.location.rt_id}/${item.id}|${item.rt_id}`;
    };
    if (r === 'location') {
        item.devs = []; // TODO: filter from data stored localy
        item.stats_link = `/dashboard/stats/${r}/${item.id}/${item.rt_id}`;
        // TODO: refactor links to a stats<LOC>/<DEV> type structure ?
        item.stats_link_new = `/dashboard/stats/${item.id}|${item.rt_id}`;
    };
    // prevent populating all items at the server
    // NOTE we use == i.s.o. === for string number comparisions
    for (const res of POPULATE_RESOURES) {
        if (item[res] && !!item[res].path && item[res].path.length > 1) {
            // lookup 
            const look_for_id = item[res].path.slice(-1)[0];
            item[res] = state[res].find(ref => ref.id == look_for_id); /* eslint-disable-line eqeqeq */
        }
    }
    return item;
}

const update_dependend_resources = (new_state, resource) => {
    const selected_item = new_state[`${resource}Selected`];
    for (const dependend_resource of RESOURCE_HIERARCHY[resource]) {
        let filtered;
        if (!!selected_item && !!selected_item.id && new_state[dependend_resource] && new_state[dependend_resource].length > 0) {
            // id may be numbers or strings == is used on purpose !  
            filtered = new_state[dependend_resource].filter(item => !!item[resource] && item[resource].id == selected_item.id); /* eslint-disable-line eqeqeq */
        } else {
            filtered = new_state[dependend_resource]; // reset
        }
        const unique_filtered = [...new Set(filtered)];
        new_state[`${dependend_resource}Filtered`] = unique_filtered;
        new_state[`${dependend_resource}Displayed`] = unique_filtered;
    }
    return new_state;
}

const HI_VAL = "zzzz";
const LO_VAL = "___";

const item_matches_active_search = (item, searchValue, tableHeadings, split_keywords = false, intl) => {
    // just look a all visual col values for now
    const look_for = searchValue.toLowerCase().trim(); // no leading of trailing spaces please

    const keywords = !!split_keywords ? look_for.split(/\s/) : [look_for]
    let found_match = false;
    for (const cell of tableHeadings) {
        const attr_val = String(get_val(item, cell.value, intl)).toLowerCase(); // thus has to do with translations
        for (const kw of keywords) {
            found_match = found_match || attr_val.includes(kw); // ANY kw 
        }
        if (found_match) break; // no need to look further
    }
    return found_match;
};

const get_row_value = (attr_name, row) => {
    // extract the raw row value first
    if (!attr_name) {
        console.warn("missing attr name to get from row", row);
        return null;
    }
    let raw_val;
    if (attr_name.includes('.')) {
        const attr_name_parts = attr_name.split('.');
        const rowObj = row[attr_name_parts[0]];
        if (!!rowObj && !!rowObj[attr_name_parts[1]]) {
            raw_val = rowObj[attr_name_parts[1]];
        } else {
            raw_val = '';
        }
    } else {
        raw_val = row[attr_name];
    };
    return raw_val;
}

const get_val = (o, attr, intl) => { // for sorting and searching only 
    const raw_val = get_row_value(attr, o);

    if (typeof (raw_val) === 'boolean') {
        return !!raw_val ? HI_VAL + 'a' : LO_VAL + 'z';
    }
    if (typeof (raw_val) === 'string') {
        const translated_string = intl.formatMessage({ id: `forms.${attr}.${raw_val}` });
        if (translated_string.startsWith(`forms.${attr}`)) {
            return raw_val.toLowerCase();
        } else {
            return translated_string.toLowerCase()
        }
    }
    if (typeof (raw_val) === 'number') {
        return raw_val;
    }
    if (raw_val === undefined || raw_val === null) {
        return LO_VAL;
    }
    return raw_val;
};

const descendingComparator = (a, b, orderBy, intl) => {
    const a_v = get_val(a, orderBy, intl);
    const b_v = get_val(b, orderBy, intl);
    if (b_v < a_v) return 1;   // STRANGE but it makes sense if you look at the sort order arrows
    if (b_v > a_v) return -1;
    return 0;
};

const getComparator = (order, orderBy, intl) => {
    if (!orderBy) orderBy = 'modifiedOn';
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy, intl) : (a, b) => -descendingComparator(a, b, orderBy, intl);
};

// ==============================|| generic item REDUCER ||============================== //

const adminReducer = (state = initialResourceState, action) => {

    switch (action.type) {
        case SET_RT_VISITS_DATA: {
            const new_state = { ...state };
            if (action.dev_id) {
                if (action.payload) {
                    // TODO: shoud we use pre calculated data from the balance endpoint ?
                    
                    // calc today counts from totals
                    if (action.payload?.day_start?.total_enters <= action.payload?.total_enters) {
                        action.payload.today_enters = action.payload.total_enters - action.payload.day_start.total_enters;
                    } else {
                        if (action.payload?.day_start?.total_exits <= action.payload?.total_enters) {
                            action.payload.today_enters = action.payload.total_enters - action.payload.day_start.total_exits;
                        } else {
                            action.payload.today_enters = action.payload.total_enters;
                        }
                    }
                    if (action.payload?.day_start?.total_exits <= action.payload?.total_exits) {
                        action.payload.today_exits = action.payload.total_exits - action.payload.day_start.total_exits;
                    } else {
                        if (action.payload?.day_start?.total_enters <= action.payload?.total_exits) {
                            action.payload.today_exits = action.payload.total_exits - action.payload.day_start.total_enters;
                        } else {
                            action.payload.today_exits = action.payload.total_exits;
                        }
                    }
                    // correct of we had a reset during the day ...
                    action.payload.today_enters += action.payload.day_start?.base_enters || 0;
                    action.payload.today_exits += action.payload.day_start?.base_exits || 0;

                    // also remember prev state for showing trends 
                    new_state.rt_visits[action.dev_id] = { ...action.payload, loading: false, prev: state.rt_visits[action.dev_id], updated: new Date() };
                } else {
                    // set loading and error states
                    new_state.rt_visits[action.dev_id] = { ...state.rt_visits[action.dev_id], loading: action.loading, error: action.error }
                }
            }
            return new_state;
        }

        case SET_ITEMS_FOR_RESOURCE: {
            const current_year = new Date().getFullYear().toString();
            let new_state = { ...state };
            if (action.resource && action.payload !== undefined) {
                const resource_path = action.resource.split('/');
                if (resource_path[0] === 'stats') {
                    const stats_id = resource_path.slice(1).join('/');
                    new_state.stats[stats_id] = action.payload;
                    if (resource_path[1] === 'location' && !!action.payload.devices && action.payload.devices.length > 0) {
                        for (const dev_data of action.payload.devices) {
                            const dev_stats_id = ['device', resource_path[2], dev_data.id, resource_path[4]].join('/');
                            new_state.stats[dev_stats_id] = dev_data.stats;
                            // extract last seen day data as near real time data
                            // eslint-disable-next-line eqeqeq
                            if (resource_path.slice(-1)[0] == current_year) {
                                const data_for_today = get_rt_counts(dev_data.stats, current_year);
                                const rt_attr =  dev_data.id.startsWith('eco') ? 'rt_visits' : 'semi_rt_visits';
                                new_state[rt_attr][dev_data.id] = { ...data_for_today, updated: new Date() };
                            }
                        }
                    } else {
                        if (resource_path.slice(-1)[0] === current_year) {
                            const data_for_today = get_rt_counts(action.payload, current_year);
                            const dev_id = resource_path[3];
                            const rt_attr =  dev_id.startsWith('eco') ? 'rt_visits' : 'semi_rt_visits';
                            new_state[rt_attr][dev_id] = { ...data_for_today, updated: new Date() };
                        }
                    }
                } else {
                    const enhanced_items = action.payload.map(item => enhance_item(action.resource, item, new_state));
                    new_state[action.resource] = enhanced_items;
                    new_state[action.resource + 'Filtered'] = enhanced_items; // find better way instead of using oldschool filtered; use derived state?
                    new_state[action.resource + 'Displayed'] = enhanced_items;
                    // new_state[action.resource + 'FilteredX'] = enhanced_items;
                }
                if (new_state.locationSelected && new_state.organisationSelected) {
                    new_state = update_dependend_resources(new_state, 'organisation');
                    new_state = update_dependend_resources(new_state, 'location');
                } else {
                    if (new_state.locationSelected) update_dependend_resources(new_state, 'location');
                    if (new_state.organisationSelected) update_dependend_resources(new_state, 'organisation');
                }
            }
            return new_state;
        }
        case ADD_ITEM_TO_RESOURCE: {
            let new_state = { ...state };
            if (action.resource && action.payload !== undefined) {
                // remove the old one if it was there, else we just get a copy ...
                // id may be numbers or strings == is used on purpose !  
                let updated_list = state[action.resource].filter(item => item.id != action.payload.id); /* eslint-disable-line eqeqeq */
                if (action.payload._action !== 'delete') {
                    // and add the new updated version
                    updated_list.push(enhance_item(action.resource, action.payload, new_state));
                }
                new_state[action.resource] = updated_list;
                new_state[`${action.resource}Filtered`] = updated_list;
                new_state[`${action.resource}Displayed`] = updated_list;

                if (new_state.locationSelected && new_state.organisationSelected) {
                    new_state = update_dependend_resources(new_state, 'organisation');
                    new_state = update_dependend_resources(new_state, 'location');
                } else {
                    if (new_state.locationSelected) update_dependend_resources(new_state, 'location');
                    if (new_state.organisationSelected) update_dependend_resources(new_state, 'organisation');
                }

            }
            return new_state;
        }

        case UPDATE_ITEM_FOR_RESOURCE: {
            let new_state = { ...state };
            const { resource, id, update_property, payload } = action;
            const id_to_update = id || payload.id; // if id not set wee expect it in the paypload 
            let item_to_update = new_state[`${resource}`]?.find((item) => item.id == id_to_update); /* eslint-disable-line eqeqeq */
            if( !item_to_update ){
                // create a new item and add to state ...
                item_to_update = {id: id_to_update};
                if( !new_state[`${resource}`] ) new_state[`${resource}`] = [];
                new_state[`${resource}`].push (item_to_update);
            }
            
            if (!!update_property) { // only update the requested property
                item_to_update[update_property] = payload;
            } else { // update ALL properties seemn in the payload
                for(const pr in payload ){
                    if( pr === 'id' ) continue;
                    item_to_update[pr] = payload[pr];
                }
                // if we have a occupence we change the status 
                if( item_to_update.occupancy ){
                    item_to_update.occupancy.status = 'need_update'; // this will trigger a cache busting reload
                }
            }
            // we now have updated the item in all filtered versions of the resource since it is a ref to the same item
            return new_state;
        }

        case LOADING_DATA: {
            let new_state = { ...state };
            new_state.loadingData = action.payload;
            return new_state;
        }

        case RESET_ITEMS_FOR_RESOURCE: {

            let new_state = { ...state };
            // reset from 
            return new_state;
        }


        case SEARCH_AND_ORDER: {
            // just remember the value
            const { searchValue, resource, translatableHeaders, order, orderBy, intl } = action;
            let new_state = { ...state };

            // first filter down results on search
            let filtered_array = new_state[`${resource}Filtered`];

            filtered_array = filtered_array.filter(item => item_matches_active_search(item, searchValue, translatableHeaders, false, intl));
            if (!filtered_array || filtered_array.length === 0) {
                filtered_array = filtered_array.filter(item => item_matches_active_search(item, searchValue, translatableHeaders, true, intl)); // split keyword only of we did not found any matched
            }

            // then order them
            if (!!order && !!orderBy) {
                new_state[`${resource}Order`] = { order: order, orderBy: orderBy }
            }
            // order based on order we got in store
            const comparator = getComparator(new_state[`${resource}Order`].order, new_state[`${resource}Order`].orderBy, intl)

            filtered_array = filtered_array.map((el, index) => [el, index]);

            filtered_array.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) return order;
                return a[1] - b[1];
            });
            const result = filtered_array.map((el) => el[0]);

            // return the items that should be displayed                    
            new_state[`${resource}Displayed`] = result;
            new_state[`${resource}Search`] = (!searchValue || searchValue === 'reset') ? '' : searchValue;
            return new_state;
        }

        case SET_ACTIVE_ITEM: {
            const { resource, id } = action.payload;
            let new_state = { ...state };
            const new_value = state[resource].find((item) => item.id === id); // may be null // id may be numbers or strings !
            new_state[`${resource}Active`] = new_value; // store the complete item not just the ID !
            // DON'T filter down all dependend 
            // MAKE SURE the org level is synced !
            if (resource !== 'organisation') {
                const new_org = state.organisation.find((item) => item.id === new_value.organisation.id);
                if (new_org) {
                    new_state.organisationSelected = new_org;
                    new_state.organisationActive = new_org;
                }
            }
            console.debug(`set ACTIVE ${resource} to: ${new_value}`);
            return new_state;
        }

        case SET_SELECTED_ITEM: {
            const { resource, id } = action.payload;
            let new_state = { ...state };
            const new_value = state[resource].find((item) => item.id === id); // may be null // id may be numbers or strings !
            new_state[`${resource}Selected`] = new_value; // store the complete item not just the ID !
            // filter down all dependend
            if (resource !== 'location') { // global location filter is not in the UI so disable it now !
                new_state = update_dependend_resources(new_state, resource);
            }
            return new_state;
        }

        default: {
            return state;
        }
    }
};

export default adminReducer;
